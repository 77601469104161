import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as routes from "../../../constants/routes";
import { getClientsAndProjects, getProjectUtilization, getChartsOfProjectUtilization } from "../../../actions/index";
import UtilizationTabular from "./UtilizationTabular";
import BarChartComponent from "./BarChartComponent";
import PieChartComponent from "./PieChartComponent";
import LineChartComponent from "./LineChartComponent";
import moment from 'moment-timezone';

class ProjectUtilization extends Component {
  constructor(props) {
    super(props); 
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

    const today = moment();
    const lastWeekEnd = today.clone().subtract(7, 'days'); // Last day of the previous week
    this.state = {
      selectedDate: {
        startDate: lastWeekEnd.toDate(),
        endDate: today.toDate()
      },
      selectedClient: null,
      selectedProjects: [],
      selectedSearchType: '',
      clients: [], // state to hold list of clients
      projects: [], // state to hold list of projects
      searchFilterData: [],
      projectWiseData: [],
      tmpSearchType: 'employee'
    }
  }

  async componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    } else {
      await this.fetchClientsAndProjects();
    }
  }

  componentDidUpdate(prevProps) {
    // listing clients and projects data
    if (this.props.clientsAndProjects && prevProps.clientsAndProjects !== this.props.clientsAndProjects) {
      this.setState({
        clients: this.props.clientsAndProjects || []
      });
    }

    // display data on tabular form
    if (this.props.searchFilterData && prevProps.searchFilterData !== this.props.searchFilterData) {
      this.setState({
        searchFilterData: this.props.searchFilterData
      });
    }
    
    // display data on Chart form
    if (this.props.projectWiseData && prevProps.projectWiseData !== this.props.projectWiseData) {
      this.setState({
        projectWiseData: this.props.projectWiseData
      });
    }
  }

  handleDate(changeDateVal, dateKey) {
    this.setState(prevState => ({
      selectedDate: {
        ...prevState.selectedDate,
        [dateKey]: changeDateVal
      }
    }));
  }

  handleClientChange = (selectedOptions) => {
    const clientId = selectedOptions;
    
    this.setState((prevState) => {
        let projects = [];

        if (clientId !== null) {
            const client = _.find(prevState.clients, { clientId: Number(selectedOptions.value) });
            if (client) {
              projects = client.projects;
            }
        }

        return {
            projects: projects,
            selectedProjects: [],
            selectedClient: clientId
        };
    });
  }
  
  handleProjectChange = (selectedOptions) => {
    selectedOptions = (selectedOptions && !_.isArray(selectedOptions)) ? [selectedOptions] : selectedOptions;
    this.setState({ selectedProjects: selectedOptions || [] });
  }
  
  handleSearchTypeChange = (event) => {
    this.setState({ tmpSearchType: event.target.value });
  }

  fetchClientsAndProjects = async() => {
    try {
      await this.props.getClientsAndProjects();
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    const { selectedClient, selectedDate, selectedProjects, tmpSearchType } = this.state;
    this.setState(prevState => ({
      selectedSearchType: prevState.tmpSearchType,
      searchFilterData: [], // Reset the tabular data
      projectWiseData: []  // Reset the chart data
    }));
    
    const projectIds = !_.isEmpty(selectedProjects) ? _.map(selectedProjects, item => item.value) : [];
    const clientId = selectedClient?.value || null;

    await this.props.getProjectUtilization(selectedDate, clientId, projectIds, tmpSearchType); // get Project Utilization data for tabular form
    await this.props.getChartsOfProjectUtilization(selectedDate, clientId, projectIds, tmpSearchType); // get Project Utilization data for charts

  }

  render() {
    const { selectedDate, selectedClient, clients, projects, selectedProjects, searchFilterData, projectWiseData, tmpSearchType, selectedSearchType } = this.state;
    const hasSearchFilterData = !_.isEmpty(searchFilterData);
    const hasProjectWiseData = !_.isEmpty(projectWiseData) && projectWiseData.chartData && projectWiseData.chartData.length > 0;
    const chartType = projectWiseData?.type || '';

    const clientOptions = clients.map(client => ({
        value: client.clientId,
        label: client.clientName
    }));
    const projectOptions = projects.map(project => ({
        value: project.projectId,
        label: project.projectName
    }));

    const today = moment().toDate();

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Project Utilization</h2>
        </div>
        <Container fluid className="mt-4 w-100 mx-auto d-flex flex-wrap flex-xl-nowrap justify-content-lg-center">
          {selectedDate.startDate && selectedDate.endDate && 
            <Form className="mb-3 d-flex flex-wrap gap-3 border rounded-8 p-2 align-items-center justify-content-center mb-xl-4" onSubmit={this.handleSubmit}>
                <div className="d-flex align-items-center">
                    <div className="date-picker-label text-nowrap me-1">Start Date:</div>
                    <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'startDate')} 
                    value={selectedDate.startDate} 
                    clearIcon={null} 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    maxDate={selectedDate.endDate < today ? selectedDate.endDate : today} // set max date based on end date and today
                    onKeyDown={(e) => e.preventDefault()}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <div className="date-picker-label text-nowrap me-1">End Date:</div>
                    <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'endDate')} 
                    value={selectedDate.endDate} 
                    clearIcon={null} 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    minDate={selectedDate.startDate} // set min date based on start date
                    maxDate={today} // set max date to today
                    onKeyDown={(e) => e.preventDefault()}
                    />
                </div>
                <div className="d-flex align-items-center" style={{width:"200px"}}>
                      <Select
                          className="flex-grow-1 theme-form-select"
                          value={selectedClient}
                          onChange={this.handleClientChange}
                          options={clientOptions}
                          placeholder="Client Selection"
                          isClearable
                      />
                </div>
                {selectedClient !== null &&
                    <div className="d-flex align-items-center" style={{width:"200px"}}>
                        <Select
                            className="flex-grow-1 theme-form-select"
                            value={selectedProjects}
                            onChange={this.handleProjectChange}
                            options={projectOptions}
                            placeholder="Project Selection"
                            isClearable
                            // isMulti
                        />
                    </div>
                }
                <div className="d-flex align-items-center team-selection">
                    <select className="form-select" value={tmpSearchType} onChange={this.handleSearchTypeChange}>
                        <option key='employee' value='employee'>Employee Wise</option>
                        <option key='team' value='team'>Team Wise</option>
                    </select>
                </div>
                <div className="d-flex align-items-center">
                    <Button 
                    type="submit" 
                    className="w-auto" 
                    // disabled={!selectedClient}
                    >Search</Button>
                </div>
            </Form>
          }
        </Container>
        <div className="container">
            <Row>
            {hasSearchFilterData &&
              <Col md={6} sm={12} xs={12}>
                <div className="resulttable position-relative">
                  <UtilizationTabular searchFilterData={searchFilterData} type={`project-${selectedSearchType}`}/>
                </div>
              </Col>
            }
            {hasProjectWiseData && 
              <Col md={6} sm={12} xs={12}>
                  {chartType === 'bar' && <BarChartComponent projectWiseData={projectWiseData.chartData} type={`project-${selectedSearchType}`} />}
                  {chartType === 'pie' && <PieChartComponent projectWiseData={projectWiseData.chartData} type={`project-${selectedSearchType}`} />}
                  {chartType === 'line' && <LineChartComponent projectWiseData={projectWiseData.chartData} type={`project-${selectedSearchType}`} />}
              </Col>
            }
            {!_.isEmpty(projectWiseData) && projectWiseData.chartData && projectWiseData.chartData.length <= 0 && 
              <Col md={12} sm={12} xs={12}>
                  <div className='p-3 rounded-6 border mt-1 bg-light text-center'>
                    <strong>No Record Found.</strong>
                  </div>
              </Col>
            }
            </Row>
        </div>
      </div>
    );
  }
}

ProjectUtilization.propTypes = {
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  getClientsAndProjects: PropTypes.func.isRequired,
  getProjectUtilization: PropTypes.func.isRequired,
  getChartsOfProjectUtilization: PropTypes.func.isRequired,
  clientsAndProjects: PropTypes.array,
  searchFilterData: PropTypes.array,
  projectWiseData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    leftMenuOpen: state.dashboard.leftMenuOpen,
    clientsAndProjects: state.projectUtilization.clientsAndProjects,
    searchFilterData: state.projectUtilization.searchFilterData,
    projectWiseData: state.projectUtilization.projectWiseData
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getClientsAndProjects, getProjectUtilization, getChartsOfProjectUtilization }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUtilization);