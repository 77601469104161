import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import Loader from "react-js-loader";
import DisLikeResponsePopUp from "./DisLikeResponsePopUp";
import { likeAnswer } from "../../../actions/index";
const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});
const DashboardResult = (props) => {
  const [question, setQuestion] = useState('');
  const [columnList, setColumn] = useState('');
  const [data, setData] = useState('');

  const [type, setType] = useState('');
  const [text, setText] = useState('');
  const [conversation_id, setConversationId] = useState('');
  const [showModel, setShowModel] = useState(false);
  const [feedback, setFeedback] = useState('');
  useEffect(() => {
    setQuestion(props.question);
    setColumn(props.results.columns);
    setData(props.results.records);
    setType(props.results.type);
    setText(props.results.text);
    setConversationId(props.results.conversation_id);
    setFeedback(props.feedback);
  }, [props.question, props.results, props.feedback]);

  const columns = useMemo(
    () =>
      data.length
        ? Object.keys(data[0]).map((columnId) => ({
          header: columnList[columnId].name ?? columnId,
          accessorKey: columnId,
          id: columnId,
          size: columnList[columnId].size
        }))
        : [],
    [columnList, data],
  );
  const handleExportData = () => {
    csvConfig.filename = "CustomerList_" + moment();
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const likeAnswer = (conversation_id) => {
    props.likeAnswer(conversation_id);
  }
  const dislikeAnswer = (conversation_id) => {
    setShowModel(true);
    console.log("showModel::::", showModel);
  }
  const closeModal = () => {
    setShowModel(false);
  }
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: true,
    enableHiding: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box className="w-100 d-flex tableicons">

        <div className='ms-auto'>
          <div className='bg-wahite border border-end-0 ps-2 py-1 tablebtndown-btn'>
            <Button className="px-0 tablebtndown" onClick={handleExportData} startIcon={<FileDownloadIcon />}></Button>
          </div>
        </div>
      </Box>
    ),
  });

  return (
    <>

      <div className='mt-2 position-relative'>
        <div className='text-end fw-semibold'>{question}<img src="/images/person.svg" className="personlogo" alt="logo" /></div>
      </div>
      {!type &&
        <div className="item d-flex">
          <Loader type="bubble-scale" bgColor={"#0075FD"} color={"#fff"} title={""} size={50} />
        </div>
      }
      {type && type !== '' && <>
        {type === 'table' && <>
          <img src="/images/logo-symbol.png" className="chatlogo" alt="logo" />
          <MaterialReactTable table={table} />
        </>
        }
        {text && text !== '' &&
          <div className='mt-2 norecord-chatlogo'><img src="/images/logo-symbol.png" className="chatlogo" alt="logo" />
            <div className='p-3 rounded-6 border mt-1 bg-light'>
              <strong>
                {text}
              </strong>
            </div></div>
        }
        {type && type !== 'rephrase' &&
          <div className='likedislike d-flex mt-2'>
            {(!feedback || feedback === 'both' || feedback === 'like') &&
              <button className={"input-group-text px-0 me-2 enter-text-button bg-transparent border-0 " + ((!conversation_id || conversation_id == '') || feedback === 'like' ? 'active' : '')} onClick={() => likeAnswer(conversation_id)} disabled={(!conversation_id || conversation_id == '') || feedback === 'like' ? true : false}>
                <img src="/images/thumb_up.svg" className="thumb_up" alt="send" />
                <img src="/images/thumb_up_blue.svg" className="thumb_up_blue" alt="send" />                
              </button>
            }
            {((conversation_id && conversation_id !== '') && (!feedback || feedback === 'both' || feedback === 'dislike')) &&
              <button className={"input-group-text px-0 enter-text-button bg-transparent border-0 " + (feedback === 'dislike' ? 'active' : '')} onClick={() => dislikeAnswer(conversation_id)} disabled={feedback === 'dislike' ? true : false}>
                <img src="/images/thumb_down.svg" className="thumb_down" alt="send" />
                <img src="/images/thumb_down_blue.svg" className="thumb_down_blue" alt="send" />
              </button>
            }
            {showModel === true &&
              <DisLikeResponsePopUp closePopup={() => closeModal()} conversation_id={conversation_id}></DisLikeResponsePopUp>
            }
          </div>
        }

      </>
      }
    </>
  )
};

DashboardResult.propTypes = {
  question: PropTypes.string.isRequired,
  results: PropTypes.shape({
    columns: PropTypes.object,
    records: PropTypes.array,
    type: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {

    currentUser: state.security.user
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      likeAnswer
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResult);
