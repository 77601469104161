
import React from "react";
import { MaterialReactTable } from 'material-react-table';
import { Paper, Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
});

const getCsvHeaders = (type) => {
    const headersMap = {
        'project-employee': [
            { id: 'taskDate', title: 'Date' },
            { id: 'clientName', title: 'Client Name' },
            { id: 'projectName', title: 'Project Name' },
            { id: 'empTeam', title: 'Employee Name' },
            { id: 'taskHours', title: 'Hours' }
        ],
        'project-team': [
            { id: 'clientName', title: 'Client Name' },
            { id: 'projectName', title: 'Project Name' },
            { id: 'teamName', title: 'Team' },
            { id: 'taskHours', title: 'Hours' }
        ],
        default: [
            { id: 'taskDate', title: 'Date' },
            { id: 'empName', title: 'Employee Name' },
            { id: 'teamName', title: 'Team' },
            { id: 'projectName', title: 'Project Name' },
            { id: 'taskHours', title: 'Hours' }
        ]
    };
    return headersMap[type] || headersMap.default;
};

class UtilizationTabular extends React.Component {

    // Prevent re-render if props (formData) have not changed
    shouldComponentUpdate(nextProps) {
        return (
        nextProps.searchFilterData !== this.props.searchFilterData
        );
    }

    state = {
        search: ''
    };

    handleExportData = () => {
        const { searchFilterData, type } = this.props;
        // Define headers manually based on the columns used in the table
        const headers = getCsvHeaders(type);

        const formattedData = (type === 'project-team') ? searchFilterData : this.preprocessDates(searchFilterData || []);
        const filteredData = this.getFilteredData(formattedData);
        const preprocessedData = this.preProcessData(filteredData);
        const extractedData = this.filterExtraKeys(preprocessedData, headers);
        csvConfig.filename = `${type === 'employee' ? 'Employee' : 'Project'}_Utilization_${moment().format('YYYY_MM_DD_HH_mm_ss')}`;
        const csv = generateCsv(csvConfig)(extractedData);
        download(csvConfig)(csv);
    };

    // Function to filter out extra keys from data
    filterExtraKeys = (data, headers) => {
        return data.map(row => {
            return headers.reduce((newRow, { id, title }) => {
                if (row.hasOwnProperty(id)) {
                    newRow[title] = row[id];
                }
                return newRow;
            }, {});
        });
    };

    preProcessData = (data) => {
        const { type } = this.props;
        const groupedData = _.groupBy(data, row => {
            switch (type) {
                case 'project-employee':
                    return `${row.clientId}_${row.projectId}_${row.taskDate}`;
                case 'project-team':
                    return `${row.clientId}_${row.projectId}`;
                default:
                    return `${row.empId}_${row.taskDate}_${row.teamName}`;
            }
        });

        return _.flatMap(groupedData, group =>
            group.map((row, index) => ({
                ...row,
                empTeam: `${row.empName} (${row.teamName})`,
                isFirst: index === 0,  // Mark first row in group
            }))
        );
    };

    calculateTotalHours = data => _.sumBy(data, 'taskHours').toFixed(2);

    formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).format(number);
    };

    handleSearchChange = (search) => {
        this.setState({ search }); // Sync state with MRT global filter
    };

    getFilteredData = (data) => {
        const { search } = this.state;
        const { type } = this.props;
        if (!search?.trim()) return data;

        const lowercasedFilter = search.toLowerCase();
        
        return data.filter(item => {
            const formattedDate = moment(item.taskDate, 'M/D/YYYY').format('M/D/YYYY').toLowerCase();
            if (type === 'project-employee') {
                return item.clientName.toLowerCase().includes(lowercasedFilter) ||
                       item.projectName.toLowerCase().includes(lowercasedFilter) ||
                       item.empName.toLowerCase().includes(lowercasedFilter) ||
                       item.teamName.toLowerCase().includes(lowercasedFilter) ||
                       formattedDate.includes(lowercasedFilter);
            } else if (type === 'project-team') {
                return item.clientName.toLowerCase().includes(lowercasedFilter) ||
                       item.projectName.toLowerCase().includes(lowercasedFilter) ||
                       item.teamName.toLowerCase().includes(lowercasedFilter);
            } else {
                return item.empName.toLowerCase().includes(lowercasedFilter) ||
                       item.teamName.toLowerCase().includes(lowercasedFilter) ||
                       item.projectName.toLowerCase().includes(lowercasedFilter) ||
                       formattedDate.includes(lowercasedFilter);
            }
        });
    };

    preprocessDates = (data) => {
        return data.map(item => ({
            ...item,
            taskDate: moment(item.taskDate, 'YYYY-MM-DD').format('M/D/YYYY'),
        }));
    };

    render() {
        const { searchFilterData, type } = this.props;
        const { search } = this.state;
        const formattedData = (type === 'project-team') ? searchFilterData : this.preprocessDates(searchFilterData || []);
        const filteredData = this.getFilteredData(formattedData);
        const preprocessedData = this.preProcessData(filteredData);

        const totalHours = this.calculateTotalHours(preprocessedData);
        const formattedTotalHours = this.formatNumber(totalHours);

        let columns = [];
        
        if(type === 'project-employee') {
            columns = [
                {accessorKey: 'taskDate', header: 'Date', size: 50, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null, enableSorting: false},
                {accessorKey: 'clientName', header: 'Client Name', size: 100, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null},
                {accessorKey: 'projectName', header: 'Project Name', size: 150, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null},
                {accessorKey: 'empTeam', header: 'Employee Name', size: 100, enableSorting: false, Footer: () => 'Total: '},
                {accessorKey: 'taskHours', header: 'Hours', size: 50, Footer: () => `${formattedTotalHours}`, enableSorting: false}
            ];
        } else if(type === 'project-team') {
            columns = [
                {accessorKey: 'clientName', header: 'Client Name', size: 100, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null, enableSorting: false},
                {accessorKey: 'projectName', header: 'Project Name', size: 150, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null, enableSorting: false},
                {accessorKey: 'teamName',header: 'Team', size: 100, Footer: () => 'Total: '},
                {accessorKey: 'taskHours', header: 'Hours', size: 100, Footer: () => `${formattedTotalHours}`, enableSorting: false}
            ];
        } else {
            columns = [
                {accessorKey: 'taskDate', header: 'Date', size: 50, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null},
                {accessorKey: 'empName', header: 'Employee Name', size: 100, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null},
                {accessorKey: 'teamName', header: 'Team', size: 100, Cell: ({ cell, row }) => row.original.isFirst ? cell.getValue() : null},
                {accessorKey: 'projectName', header: 'Project Name', size: 150, Footer: () => 'Total: ', enableSorting: false},
                {accessorKey: 'taskHours', header: 'Hours', size: 50, Footer: () => `${formattedTotalHours}`, enableSorting: false}
            ];
        }

        return (
            <Paper style={{ padding: 0 }}>
                <MaterialReactTable
                    columns={columns}
                    data={preprocessedData}
                    enableRowSelection={false} // Disable row selection
                    enableColumnActions={false} // Disable column actions (e.g., hiding)
                    enableDensityToggle={false} // Disable density toggle
                    enableColumnFilters={false} // Disable column filters
                    enablePagination={true} // Disable pagination
                    enableSorting={false} // Enable sorting
                    enableHiding={false} // Disable hiding columns
                    // enableFilters={true}
                    enableGlobalFilter={true} // Enable MRT's global search
                    enableFullScreenToggle={true}
                    enableTopToolbar={true}
                    enableBottomToolbar={true}
                    enableFooter={true}
                    muiTableProps={{
                        stickyHeader: true,
                        sx: {
                            '& .MuiTableCell-root': {
                                borderBottom: '1px solid #ddd',
                            },
                        },
                    }}
                    muiTableFooterCellProps={{
                        sx: {
                            fontWeight: '700',
                            fontSize: "0.875rem",
                            color: "rgba(0, 0, 0, 0.87)"
                        },
                    }}
                    initialState={{
                        globalFilter: search, // Sync MRT global filter with state on load
                    }}
                    state={{
                        globalFilter: search, // Keep MRT's global filter in sync with the component's state
                    }}
                    onGlobalFilterChange={this.handleSearchChange}
                    renderTopToolbarCustomActions={() => (
                        <Box className="w-100 d-flex tableicons">
                            <div className='ms-auto'>
                                <div className='bg-wahite border border-end-0 ps-2 py-1 tablebtndown-btn'>
                                    <Button className="px-0 tablebtndown" onClick={this.handleExportData} startIcon={<FileDownloadIcon />}></Button>
                                </div>
                            </div>
                        </Box>
                    )}
                />
            </Paper>
        );
    }
}

UtilizationTabular.propTypes = {
  searchFilterData: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['project-employee', 'project-team', 'employee']).isRequired
};

export default UtilizationTabular;
