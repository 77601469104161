import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
// import { BarChart } from "react-chartjs-library";
import { fontStyleForChart } from "../../ResourceChart/helper";

class BarChartComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        dataSets: [],
        labelArray: [],
        // projectWiseUtilizationData: [],
        datakey: ["taskHours"]
      };

      this.type = this.props.type;
    }

    componentDidMount() {
        this.processData(this.props?.projectWiseData || []);
    }
    
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.projectWiseData, this.props.projectWiseData)) {
          this.processData(this.props?.projectWiseData || []);
        }
    }

    processData() {
        // Sort the array by the "projectName" property in ascending order
        let dataArr = [];
        let labelArr = [];
        let utilizationTitle = '';
        if(this.type === 'project-employee') {
            dataArr = _.sortBy(this.props?.projectWiseData, item => item.empName.toLowerCase());
            labelArr = dataArr.map(ele => ele.empName);
            utilizationTitle = 'Project Utilization';
        } else if(this.type === 'project-team') {
            dataArr = _.sortBy(this.props?.projectWiseData, item => item.teamName.toLowerCase());
            labelArr = dataArr.map(ele => ele.teamName);
            utilizationTitle = 'Project Utilization';
        } else {
            dataArr = _.sortBy(this.props?.projectWiseData, item => item.projectName.toLowerCase());
            labelArr = dataArr.map(ele => ele.projectName);
            utilizationTitle = 'Employee Utilization';
        }
        const utilizationData = dataArr.map(ele => ele.taskHours);
        // const tooltipEmpWiseData = dataArr.map(ele => ele.empData);
        
        const dataValues = [{
          label: `${utilizationTitle}`,
          data: utilizationData,
          backgroundColor: "#0075FD",
        //   tooltipEmpWiseData: tooltipEmpWiseData
        }];

        this.setState({
            data: dataArr,
            dataSets: dataValues,
            labelArray: labelArr,
            // projectWiseUtilizationData: dataValues
        });

        this.decimals = 0;
        this.options = {
            plugins: {
                title: {
                    display: false,
                    text: `${utilizationTitle}`,
                    align: 'start',
                    font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                    },
                    color: fontStyleForChart.color,
                    padding: {
                        bottom : 20,
                    }
                },
                legend: {
                    display: false,
                    position: 'top',
                    align: 'end',
                    labels: {
                        filter: (legendItem, data) => data.datasets[0].data[legendItem.index] !== 0,
                        font: {
                          size: fontStyleForChart.size,
                          weight: fontStyleForChart.weight,
                          family: fontStyleForChart.family
                        },
                        color: fontStyleForChart.color
                    }
                },
                datalabels: {
                    display: true,
                },
                tooltip: {
                    filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            // const empData = tooltipItem.dataset.tooltipEmpWiseData[tooltipItem.dataIndex];
                            let tooltipHtml = '';
                            /* _.forEach(empData, (emp) => {
                                console.log("emp ==>>", emp)
                                tooltipHtml += `${emp.empName}: ${emp.taskHours} Hours \n`
                            }); */
                            tooltipHtml += `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} Hours`;
                            return tooltipHtml
                        },
                        footer: function (items) {
                            //return 'Total: $' + (items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2)
                        }
                    }
                }
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        callback: function(value) {
                            // Access the label from the data.labels array using the value as the index
                            let label = this.chart.data.labels[value];
                            // Truncate if necessary
                            if (label && label.length > 15) {
                                return label.substr(0, 15) + '...'; // Truncate and add ellipsis
                            } 
                            return label;
                        },
                        font: {
                            size: fontStyleForChart.size,
                            weight: fontStyleForChart.weight,
                            family: fontStyleForChart.family
                        },
                        color: fontStyleForChart.color
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: (value) => {
                            return value.toFixed(this.decimals);
                        },
                        font: {
                            size: fontStyleForChart.size,
                            weight: fontStyleForChart.weight,
                            family: fontStyleForChart.family
                        },
                        color: fontStyleForChart.color
                    }
                },
            },
            maintainAspectRatio: false,
            barThickness: 20, // Set the width of the bar lines
        };
    }

    render(){
      const { dataSets, labelArray } = this.state;
      const dataVal = {
          labels: labelArray,
          datasets: dataSets
      };

      return (
        <div id="bar-chart" className="ratio ratio-1x1">
            {this.state.dataSets !== null && this.state.dataSets.length > 0 && (
                <Bar options={this.options} data={dataVal} />
            )}
        </div>
      );
    }
};

BarChartComponent.propTypes = {
    projectWiseData: PropTypes.array.isRequired, // Validate history prop
    type: PropTypes.string.isRequired, // Validate history prop
};

export default BarChartComponent;
