import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { fontStyleForChart } from "../../ResourceChart/helper";

class LineChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          dataSets: [],
          labelArray: [],
          datakey: ["taskHours"]
        };

        this.type = this.props.type;
    }

    componentDidMount() {
        this.processData(this.props?.projectWiseData || []);
    }
    
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.projectWiseData, this.props.projectWiseData)) {
          this.processData(this.props?.projectWiseData || []);
        }
    }

    processData() {
        // Sort the array by the "duration" property in ascending order
        const dataArr = this.props?.projectWiseData;
        const labelArr = dataArr.map(ele => ele.duration);
        const utilizationData = dataArr.map(ele => ele.taskHours);
        
        const dataValues = [{
          fill: true,
          label: `Total Utilization`,
          data: utilizationData,
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgba(54, 162, 235, 1)'
        }];

        this.setState({
            data: dataArr,
            dataSets: dataValues,
            labelArray: labelArr
        });

        this.options = {
            plugins: {
                title: {
                    display: false,
                    text: `${this.type} Utilization`,
                    align: 'start',
                    font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                    },
                    color: fontStyleForChart.color,
                    padding: {
                        bottom : 20,
                    }
                },
                legend: {
                    display: false,
                    position: 'top',
                    align: 'end',
                    labels: {
                      flow: 'left',
                      boxWidth: 20,
                      boxHeight: 15,
                      font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                      },
                      color: fontStyleForChart.color
                    }
                },
                datalabels: {
                    display: true,
                },
                tooltip: {
                    filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} Hours`;
                        }
                    }
                }
            },
            scales: {
                x: {
                  grid: {
                    color: function(context) {
                      return 'rgba(0, 0, 0, 0.1)';
                    },
                  },
                  stacked: true,
                  ticks: {
                    font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                    },
                    color: fontStyleForChart.color
                  }
                },
                y: {
                  ticks: {
                    font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                    },
                    color: fontStyleForChart.color,
                    autoSkip: false,
                    tickLength: 20 // Change the value as needed
                  }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };
    }

    render(){
        const { dataSets, labelArray } = this.state;
        const dataVal = {
            labels: labelArray,
            datasets: dataSets
        };

        return (
            <div id="line-chart" className="ratio ratio-1x1">
                {this.state.dataSets !== null && this.state.dataSets.length > 0 && (
                    <Line options={this.options} data={dataVal} />
                )}
            </div>
        );
    }

};

LineChartComponent.propTypes = {
    projectWiseData: PropTypes.array.isRequired, // Validate history prop
    type: PropTypes.string.isRequired, // Validate history prop
};

export default LineChartComponent;