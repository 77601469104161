import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Line } from 'react-chartjs-2';
import { fetchMonth, labelOrdinal, fontStyleForChart } from "./helper";

class AreaChartComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        dataSets: [],
        labelArray: []
      };
    }

    componentDidMount() {
      this.processData(this.props?.info || []);
    }
  
    componentDidUpdate(prevProps) {
      if (!_.isEqual(prevProps.info, this.props.info)) {
        this.processData(this.props?.info || []);
      }
    }
    processData = (info) => {
      const sortedArray = _.sortBy(info, 'numOfWeek');
      const labelArray = _.uniq(sortedArray.map(element => element.numOfWeek));
      const groupByMonth = _.groupBy(sortedArray, 'month');
      
      // Default colors for the datasets
      const defaultColors = [
        { // Default color for legend 1
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            borderColor: 'rgba(255, 99, 132, 1)'
        },
        { // Default color for legend 2
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)'
        }
        // Add more colors as needed
      ];

      // Reverse the default colors array
      const reversedDefaultColors = defaultColors.slice().reverse();

      const dataSets = _.map(groupByMonth, (group, month) => {
        // Assign colors from reversed array
        const colorIndex = Object.keys(groupByMonth).length - 1 - Object.keys(groupByMonth).indexOf(month);
        const color = reversedDefaultColors[colorIndex % reversedDefaultColors.length];

        return {
            fill: true,
            label: fetchMonth(Number(moment(month, "YYYYM").format("M"))),
            data: group.map(val => ({
              x: val.numOfWeek,
              y: val.utilization,
              week: val.week  // Include the week in the data point for tooltip use
            })),
            borderWidth: 1,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
        };
      });

      this.setState({ dataSets, labelArray });
      this.options = {
        plugins: {
          title: {
            display: true,
            text: 'Last Month VS This Month',
            align: 'start',
            font: {
              size: fontStyleForChart.titleSize,
              weight: fontStyleForChart.weight,
              family: fontStyleForChart.family
            },
            color: fontStyleForChart.color,
            padding: {
              bottom : -24,
              top: 5,
            }
          },
          legend: {
            display: true,
            position: 'top',
            align: 'end',
            labels: {
              flow: 'left',
              boxWidth: 20,
              boxHeight: 15,
              color: fontStyleForChart.color,
              font: {
                size: fontStyleForChart.titleSize,
                weight: fontStyleForChart.weight,
                family: fontStyleForChart.family
              }
            }
          },
          tooltip: {
            callbacks: {
              title: function(tooltipItem) {
                if(!tooltipItem[0].raw?.week) {
                  return `${tooltipItem[0].raw.x}`;
                }
                const [startDate, endDate] = tooltipItem[0].raw.week.split('_');
                const startWeekDate = moment(startDate, "YYYY-MM-DD").format("MM/DD/YYYY");
                const endWeekDate = moment(endDate, "YYYY-MM-DD").format("MM/DD/YYYY");
                return `${startWeekDate}-${endWeekDate}`; // Example title based on index
              },
              label: function (tooltipItem, data) {
                /* // tooltipItem.dataset.label + " Utilization: " + (tooltipItem.formattedValue) + "%"
                if (!tooltipItem.raw?.week) {
                  return ''; // Return empty string if no week information is available
                } */
                return `${tooltipItem.dataset.label} Utilization: ${tooltipItem.formattedValue}%`;
              },
            }
          }
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              color: function(context) {
                return 'rgba(0, 0, 0, 0.1)';
              },
            },
            stacked: true,
            ticks: {
                font: {
                  size: fontStyleForChart.size,
                  weight: fontStyleForChart.weight,
                  family: fontStyleForChart.family
                },
                color: fontStyleForChart.color,
                callback: (value, index, values) => {
                    // Calculate the label number based on index
                    return labelOrdinal(index + 1) + ' Week';
                },
            }
          },
          y: {
            ticks: {
              color: fontStyleForChart.color,
              font: {
                size: fontStyleForChart.size,
                weight: fontStyleForChart.weight,
                family: fontStyleForChart.family
              },
              autoSkip: false,
              callback: (value, index, values) => {
                return value + '%';
              },
              tickLength: 20 // Change the value as needed
            }
          }
        },
        maintainAspectRatio: false,
      };
    };

    render(){
      const { dataSets, labelArray } = this.state;
      const dataVal = {
          labels: labelArray,
          datasets: dataSets
      };

      return (
        <div id="bar-chart" className="ratio ratio-1x1">
            <Line options={this.options} data={dataVal} />
        </div>
      );
    }
};

AreaChartComponent.propTypes = {
    info: PropTypes.array.isRequired, // Validate history prop
};

export default AreaChartComponent;
