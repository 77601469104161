import axios from "axios";
import Progress from "react-progress-2";
import { alertService } from "../components/GlobalComponent/alertService";
export const NODE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = `${NODE_URL}api`;
export const ALLOWED_WHITELISTED_DOMAINS = process.env.REACT_APP_WHITELISTED_DOMAINS.split(',');

export const GET_ERRORS = "GET_ERRORS";

//config module
export const FETCH_CONFIG = "FETCH_CONFIG";
export const SET_LEFT_MENU = "SET_LEFT_MENU";
//user module
export const FETCH_USER = "FETCH_USER";
export const USERS_RESET = "USERS_RESET";

// dashboard module
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const FETCH_ASK_RESULTS = "FETCH_ASK_RESULTS";
export const SET_QUESTION = "SET_QUESTION";

//utilization module
export const FETCH_TEAM_REPORT = "FETCH_TEAM_REPORT";
export const FETCH_MONTHLY_REPORT = "FETCH_MONTHLY_REPORT";
export const FETCH_USER_MONTHLY_REPORT = "FETCH_USER_MONTHLY_REPORT";
export const FETCH_USER_REPORT = "FETCH_USER_REPORT";

//Resource utilization module
export const FETCH_RESOURCE_TEAM_REPORT = "FETCH_RESOURCE_TEAM_REPORT";
export const FETCH_RESOURCE_MONTHLY_REPORT = "FETCH_RESOURCE_MONTHLY_REPORT";
export const FETCH_TEAMS = "FETCH_TEAMS";

// Employee utilization module
export const FETCH_TEAMS_EMPLOYEES = "FETCH_TEAMS_EMPLOYEES";
export const FETCH_EMPLOYEES_UTILIZATION = "FETCH_EMPLOYEES_UTILIZATION";
export const FETCH_EMPLOYEES_UTILIZATION_CHART = "FETCH_EMPLOYEES_UTILIZATION_CHART";

// Project utilization module
export const FETCH_CLIENT_PROJECTS = "FETCH_CLIENT_PROJECTS";
export const FETCH_PROJECTS_UTILIZATION = "FETCH_PROJECTS_UTILIZATION";
export const FETCH_PROJECT_UTILIZATION_CHART = "FETCH_PROJECT_UTILIZATION_CHART";

//Funnel Module
export const FETCH_FUNNEL_RESULTS = "FETCH_FUNNEL_RESULTS";
export const SET_FUNNEL_QUESTION = "SET_FUNNEL_QUESTION";

export const SAMPLE_QUESTIONS = "SAMPLE_QUESTIONS";
export const FEEDBACK_LIKE = "FEEDBACK_LIKE";
export const FEEDBACK_DISLIKE = "FEEDBACK_DISLIKE";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    let url = new URL(config.url)
    let originURL = url.origin;
    let token = '';

    token = sessionStorage.getItem("jwtToken");

    if (!config.loaderHide || config.loaderHide === "false") {
      Progress.show();
    }


    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    Progress.hide();
    return response;
  },
  function (error) {
    Progress.hide();

    if (error.response && error.response.status === 401) {

      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("userinfo");
      window.location.href = "/login";
      return Promise.reject(error);
    }

    if (error.response && (error.response.status === 500 || error.response.status === 400)) {
      if (!error?.response?.data?.errorMessage) {
        alertService.error("Something went wrong. Please contact Administrator.", { autoClose: true });
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export function handleError(error, dispatch) {
  if (error.response) {

    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  } else if (error) {
    dispatch({
      type: GET_ERRORS,
      payload: { errorMessage: "Something went wrong. Please contact Administrator." },
    });
    // The request was made but no response was received
    //console.log("Error", error.message);
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    //console.log("Error", error.message);
  }
}

export function toggleModal(ms) {
  return {
    type: ms.type,
    payload: ms,
  };
}

export function setMenuToggle(menu) {
  return {
    type: SET_LEFT_MENU,
    payload: menu,
  };
}

