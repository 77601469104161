import { FETCH_CLIENT_PROJECTS, FETCH_PROJECTS_UTILIZATION, FETCH_PROJECT_UTILIZATION_CHART } from "../actions/index";

const INITIAL_STATE = {
  clientsAndProjects: [],
  searchFilterData: [],
  projectWiseData: {}
};

export default function employeeUtilizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CLIENT_PROJECTS:
      return {
        ...state,
        clientsAndProjects: action.payload,
      }
    case FETCH_PROJECTS_UTILIZATION:
      return {
        ...state,
        searchFilterData: action.payload,
      }
    case FETCH_PROJECT_UTILIZATION_CHART:
      return {
        ...state,
        projectWiseData: action.payload,
      }
    default:
      return state;
  }
}
