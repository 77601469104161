import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import "rsuite/dist/rsuite.min.css";
import { bindActionCreators } from "redux";
import { auth } from '../../../firebase';
import { signOut } from 'firebase/auth';
import { userLogout, setMenuToggle } from "../../../actions/index";
import { isMobile } from 'react-device-detect';
import { NavLink } from "react-router-dom";
import { Sidenav, Nav } from 'rsuite';
class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      menuExpand: true,
      expanded: true,
      activeKey: '1',
    };
   
  }
  componentDidMount() {
    let currentUser = this.props.currentUser;
    this.setState({ user: currentUser });
    if (isMobile) {
      this.props.setMenuToggle(true);
      this.setState({ menuExpand: true });
    }    

    setTimeout(() => {
      const pathToKeys = {
          '/customerinquire': { defaultOpenKey: '2', openKey:'2', activeKey: '2-1' },
          '/customer-utilization': { defaultOpenKey: '2', activeKey: '2-2' },
          '/resource': { defaultOpenKey: '3', activeKey: '3-1' },
          '/resource-utilization': { defaultOpenKey: '3', activeKey: '3-2' },
          '/employee-utilization': { defaultOpenKey: '3', activeKey: '3-3' },
          '/project-utilization': { defaultOpenKey: '3', activeKey: '3-4' },
          '/funnelinquire': { defaultOpenKey: '4', activeKey: '4-1' }
      };
      const currentPath = window.location.pathname;
      const { defaultOpenKey, activeKey } = pathToKeys[currentPath] || { defaultOpenKey: '0', activeKey: '1' };

      this.setState({ defaultOpenKey, activeKey });
      if (currentPath === '/customerinquire') {
        this.setState({ openKey:'2' });
      }
    }, 200);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.leftMenuOpen !== prevProps.leftMenuOpen) {
      this.setState({ menuExpand: this.props.leftMenuOpen });
    }
  }
  logout() {
    signOut(auth).then((res) => {
      userLogout();
    }).catch((error) => {
    });
  };
  handelMenu() {
    this.props.setMenuToggle(!this.state.menuExpand);
    this.setState(prevState => ({
      menuExpand: !prevState.menuExpand
    }));
  }   
  setActiveKey(activeKey) {
    this.setState({ activeKey: activeKey });
    if(activeKey){      
      this.setState({ defaultOpenKey: '3' });
    }
  }
 
  render() {

    return (
      <div className={`mx-auto p-2 sidebar w-100 d-flex flex-column history-block ${this.state.menuExpand ? "" : "menu-hide"}`}>
        <button className={`px-2 pt-1 pb-2 border-start-0 border position-absolute handelmenu ${this.state.menuExpand ? "" : "collapsed"}`} onClick={() => this.handelMenu()}>
          <img src="/images/menu.svg" alt="send" width={20} />
        </button>
        <div className="text-center">
          <img className="w-244 mb-3" src="images/Logo2.png" alt="Your Company" />
          <div className="font-16 fw-bold">Welcome Back</div>
          <div className="font-18 fw-bold font-blue mb-3">{this.state.user.name}</div>
        </div>


        {this.state.defaultOpenKey &&
          <Sidenav expanded={this.state.expanded} defaultOpenKeys={[this.state.defaultOpenKey]} appearance="default" >
            <Sidenav.Body>
              <Nav className="inquire-nav" activeKey={this.state.activeKey} onSelect={(id) => this.setActiveKey(id)}>
                <Nav.Item eventKey="1" ><NavLink to="/dashboard"> Dashboard </NavLink></Nav.Item>
                <Nav.Menu placement="rightStart" eventKey="2" title="Customers/Users" >
                  <Nav.Item eventKey="2-1"><NavLink to="/customerinquire"> Inquire </NavLink></Nav.Item>
                  <Nav.Item eventKey="2-2"><NavLink to="/customer-utilization" >Usage</NavLink></Nav.Item>
                  {/* <Nav.Item eventKey="2-3"><NavLink to="/" >Analytics</NavLink></Nav.Item> */}
                </Nav.Menu>
                <Nav.Menu placement="rightStart" eventKey="3" title="Pro Services Utility">
                  <Nav.Item eventKey="3-1"><NavLink to="/resource" > Inquire </NavLink></Nav.Item>
                  <Nav.Item eventKey="3-2"><NavLink to="/resource-utilization" >Utilization</NavLink></Nav.Item>
                  <Nav.Item eventKey="3-3"><NavLink to="/employee-utilization" >Employee Utilization</NavLink></Nav.Item>
                  <Nav.Item eventKey="3-4"><NavLink to="/project-utilization" >Project Utilization</NavLink></Nav.Item>
                  {/* <Nav.Item eventKey="3-3"><NavLink to="">Forecast</NavLink></Nav.Item>
                  <Nav.Item eventKey="3-3"><NavLink to="">Availability</NavLink></Nav.Item> */}
                </Nav.Menu>
                <Nav.Menu placement="rightStart" eventKey="4" title="Funnel Data">
                  <Nav.Item eventKey="4-1"><NavLink to="/funnelinquire" > Inquire </NavLink></Nav.Item>                  
                </Nav.Menu>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        }
        <Nav className="w-100 mt-auto d-flex flex-column py-0 pe-1 bg-transparent border-top">
          <NavLink to="#" eventkey="logout" className={`text-decoration-none text-dark font-16 w-100 fw-bold pt-2 d-flex`} onClick={() => this.logout()}>
            <img src="/images/logout.svg" alt="send" width={20} className="me-2"/>
            Logout
          </NavLink>
        </Nav>
      </div>
    );
  }
}

LeftNav.propTypes = {
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  setMenuToggle: PropTypes.func,
  leftMenuOpen: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    leftMenuOpen: state.dashboard.leftMenuOpen,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      setMenuToggle
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);