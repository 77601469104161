
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Chart from "../../ResourceChart";
import * as routes from "../../../constants/routes";
import ExportExcel from './ExportExcel';
import { getAllTeams, getResourceUtilizationReport } from "../../../actions/index";

class ResourceUtilization extends Component {
  constructor(props) {
    super(props); 
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

    const currentDate = moment();
    const startDate = this.calculateFirstMonday(currentDate);
    const endDate = this.calculateLastFriday(currentDate.endOf('month'));

    this.state = {
      selectedDate: {
        startDate: startDate.toDate(),
        endDate: endDate.toDate()
      },
      team: 'all',
      teams: [], // state to hold list of teams
    }
  }

  async componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    } else {
      await this.fetchTeams();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.teams && prevProps.teams !== this.props.teams) {
      this.setState({
        teams: this.props.teams
      });
    }
  }

  calculateFirstMonday = (currentDate) => {
    let start = moment(currentDate).startOf('month');
    // Check if start date is weekend
    if (start.day() === 0) { // Sunday
      start.add(1, 'day'); // Move to Monday
    } else if (start.day() === 6) { // Saturday
      start.add(2, 'day'); // Move to Monday
    } else {
      start = start.startOf('isoWeek')
    }
    return start;
  }

  calculateLastFriday = (currentDate) => {
    let end = moment(currentDate).endOf('month');
    // Check if end date is weekend
    if (end.day() === 0) { // Sunday
      end.subtract(2, 'day'); // Move to Friday
    } else if (end.day() === 6) { // Saturday
      end.subtract(1, 'day'); // Move to Friday
    } else {
      end = end.startOf('isoWeek').add(4, 'days');
    }
    return end;
  }

  handleDate(changeDateVal, dateKey) {
    this.setState(prevState => ({
      selectedDate: {
        ...prevState.selectedDate,
        [dateKey]: changeDateVal
      }
    }));
  }

  handleTeamChange = (event) => {
    this.setState({ team: event.target.value });
  }

  fetchTeams = async() => {
    try {
      await this.props.getAllTeams();
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  }

  tileMondayEnabled = ({ date }) => {
    if (!date) return true; // Handle case where date is null or undefined
    const day = date.getDay();
    // Disable all days except Monday (1) and Friday (5)
    return day !== 1;
  }
  
  tileFridayEnabled = ({ date }) => {
    if (!date) return true; // Handle case where date is null or undefined
    const day = date.getDay();
    // Disable all days except Monday (1) and Friday (5)
    return day !== 5;
  }

  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Utilization Report</h2>
        </div>
        <Container fluid className="mt-4 w-100 mx-auto d-flex flex-wrap flex-xl-nowrap justify-content-lg-center">
          {this.state.selectedDate.startDate && this.state.selectedDate.endDate && 
              <>
              <Form className="mb-3 row ms-xxl-auto me-md-3 max-w-760 border rounded-8 py-2 align-items-center mb-xl-4 col-xxl-8" onSubmit={(e) => {e.preventDefault();}}>
                <div className="d-flex col-12 col-md-4 align-items-center mb-2 mb-md-0">
                  <div className="date-picker-label me-2 text-nowrap me-1">Start Date:</div>
                  <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'startDate')} 
                    value={this.state.selectedDate.startDate} 
                    clearIcon={null}
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    maxDate={this.state.selectedDate.endDate} // set max date based on end date
                    tileDisabled={this.tileMondayEnabled} // Add tileDisabled prop
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
                <div className="d-flex col-12 col-md-4 align-items-center mb-2 mb-md-0">
                  <div className="date-picker-label me-2 text-nowrap me-1 ms-0 ms-md-1">End Date:</div>
                  <DatePicker 
                    className={"datepicker flex-grow-1 me-0 me-md-4"}
                    onChange={(val) => this.handleDate(val, 'endDate')} 
                    value={this.state.selectedDate.endDate} 
                    clearIcon={null}
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    minDate={this.state.selectedDate.startDate} // set min date based on start date
                    tileDisabled={this.tileFridayEnabled} // Add tileDisabled prop
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </div>
                <div className="d-flex col-12 col-md-4 align-items-center team-selection">
                  <select className="form-select" value={this.state.team} onChange={this.handleTeamChange}>
                      <option key='all' value='all'>
                      Team Selection
                      </option>
                    {this.state.teams.map((team) => (
                      <option key={team.team_id} value={team.team_name}>
                        {team.team_name}
                      </option>
                    ))}
                  </select>
                </div>
              </Form>
              <ExportExcel getDataFunction={this.props.getResourceUtilizationReport} dateInfo={this.state.selectedDate} team={this.state.team}/>
              </>
          }
        </Container>
        <div className="container">
          <Chart dateInfo={this.state.selectedDate} type="resource" team={this.state.team}/>
        </div>
      </div>
    );
  }
}

ResourceUtilization.propTypes = {
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  getAllTeams: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  getResourceUtilizationReport: PropTypes.func
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    teams: state.resourceUtilization.teams
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getAllTeams, getResourceUtilizationReport }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResourceUtilization);