export const fetchMonth = (month) => {
  const shortMonthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];
  return shortMonthNames[month - 1] || '';
};

export const fetchDay = (day) => parseInt(day) || '';

export const fetchDayNumber = (date) => {
  const day = fetchDay(date.split("-")[2]);
  return `${day}`;
};

export const GFG_sortFunction = (a, b) => {
  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  return dateA > dateB ? 1 : -1;
};

export const fontStyleForChart = {
  family: '"Roboto","Helvetica","Arial",sans-serif',
  color: '#212529',
  size: 12,
  titleSize: 16,
  weight: '400'
}