
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SET_CURRENT_USER, toggleModal } from "../actions";
import Dashboard from "./mainTabs/dashboard/Dashboard.js";
import CustomerBoard from "./mainTabs/customerboard/CustomerBoard.js";
import ResourceBoard from "./mainTabs/resourceboard/ResourceBoard.js";
import FunnelBoard from "./mainTabs/funnelboard/FunnelBoard.js";
import CustomerUtilization from "./mainTabs/customer-utilization/CustomerUtilization.js";
import ResourceUtilization from "./mainTabs/resource-utilization/ResourceUtilization.js";
import EmployeeUtilization from "./mainTabs/utilization/EmployeeUtilization.js";
import ProjectUtilization from "./mainTabs/utilization/ProjectUtilization.js";
import * as routes from "../constants/routes";
import setJWTToken from "../securityUtils/setJWTToken";
import { Alert } from "./GlobalComponent/Alert";
import Login from "./mainTabs/authorize/Login";
import ForgotPassword from "./mainTabs/authorize/ForgotPassword";
import Error404 from "./mainTabs/error/error404";

import LeftNav from "./mainTabs/header/left-nav";
import Footer from "./mainTabs/footer/footer";

function App() {
  let user = useSelector((state) => state.security.user);
  let validLogin = sessionStorage.getItem("jwtToken");

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const leftMenuOpen = useSelector((state) => state.dashboard.leftMenuOpen);
  const jwtToken = sessionStorage.jwtToken;

  if (jwtToken) {
    setJWTToken(jwtToken);
    let decoded = jwt_decode(jwtToken);
    if (Object.values(user).length === 0) {
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
      
    }

  }
  return (
    <BrowserRouter>
      {validLogin &&
        <LeftNav
          authUser={null}
          version={""}
          value={value}
          setValue={setValue}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        ></LeftNav>
      }
      <div className={`d-flex flex-column flex-grow-1 position-relative ${leftMenuOpen ? "" : "menu-hide"} ${validLogin ? "usertable" : ''}`}>
        {validLogin ? <Alert></Alert> : null}
        <Switch>
          <Route exact strict path={routes.LANDING} component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact key={routes.FORGOT_PASSWORD} path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route exact key={routes.DASHBOARD} path={routes.DASHBOARD} component={Dashboard} />
          <Route exact key={routes.CUSTOMERINQUIRE} path={routes.CUSTOMERINQUIRE} component={CustomerBoard} />
          <Route exact key={routes.RESOURCE} path={routes.RESOURCE} component={ResourceBoard} />
          <Route exact key={routes.UTILIZATION} path={routes.UTILIZATION} component={CustomerUtilization} />
          <Route exact key={routes.EMPLOYEE_UTILIZATION} path={routes.EMPLOYEE_UTILIZATION} component={EmployeeUtilization} />
          <Route exact key={routes.PROJECT_UTILIZATION} path={routes.PROJECT_UTILIZATION} component={ProjectUtilization} />
          <Route exact key={routes.RESOURCE_UTILIZATION} path={routes.RESOURCE_UTILIZATION} component={ResourceUtilization} />
          <Route exact key={routes.FUNNELINQUIRE} path={routes.FUNNELINQUIRE} component={FunnelBoard} />
          
          <Route component={Error404} />
        </Switch>
        {validLogin &&
          <Footer></Footer>
        }
      </div>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {
    leftMenuOpen: state.dashboard.leftMenuOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
