
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as routes from "../../../constants/routes";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

  }

  componentDidMount() {

    let currentUser = this.props.currentUser;
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }

  }

  componentDidUpdate(prevProps) {

  }


  render() {

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <div className=" w-100 max-w-896 mx-auto container-fluid pt-5">
            <h1 className="font-32 text-dark fw-bold my-2">BIOMC</h1>
            <h2 className="mt-5 font-32 text-dark fw-bold my-2">Business Inquiry, Observability, Monitoring/Management Capability</h2>
          </div>
        </div>
      </div >
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop  
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);