import React from "react";
import moment from 'moment-timezone';

export default function Footer(props) {
  return (
    <div className="mt-auto text-center py-2 font-12 bg-light-1 bg-dark-1 footer-dark-text">
      Copyright © {moment().year()} by BLATTNER TECHNOLOGIES. All Rights Reserved.
    </div>
  );
}
