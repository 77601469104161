import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from 'react-bootstrap';
import { sendFeedback } from "../../../actions/index";
class DisLikeResponsePopUp extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      show: true,
      title: 'Provide additional feedback',
      description: '',
      confirmButtonText: 'Submit',
      closeButtonText: 'Close',
      feedback: '',
      addtional_notes: '',
      conversation_id: ''
    };
  }

  componentDidMount() {
    let conversation_id = this.props.conversation_id;
    this.setState({ conversation_id: conversation_id });
  }

  componentDidUpdate(prevProps) {
    if (this.props.feedback && prevProps.feedback !== this.props.feedback) {
      this.props.closePopup();
    }
  }

  selectFeedback(feedback) {
    this.setState({ feedback: feedback });
  }
  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendFeedback() {
    let payload = {
      feedback: this.state.feedback,
      addtional_notes: this.state.addtional_notes,
      conversation_id: this.state.conversation_id
    }
    this.props.sendFeedback(payload);
  }
  render() {
    return (
      <div>
        <Modal show={"true"} onHide={this.props.closePopup} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title className="font-18 font-quicksand">{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-items-start">
              {/* <button type="button" className={"m-1 question-suggestion btn " + (this.state.feedback == "Don't like the style" ? 'btn-primary' : 'btn-secondary')} onClick={(e) => this.selectFeedback("Dont like the style ")}>
                Don't like the style
              </button> */}
              <button type="button" className={"m-1 question-suggestion btn " + (this.state.feedback == "Don't like the style" ? 'btn-primary' : 'btn-secondary')} onClick={(e) => this.selectFeedback("Don't like the style")}>
                Don't like the style
              </button>
              <button type="button" className={"m-1 question-suggestion btn " + (this.state.feedback == "Being lazy" ? 'btn-primary' : 'btn-secondary')} onClick={(e) => this.selectFeedback("Being lazy")}>
                Being lazy
              </button>
              <button type="button" className={"m-1 question-suggestion btn " + (this.state.feedback == "Didn't fully follow instructions" ? 'btn-primary' : 'btn-secondary')} onClick={(e) => this.selectFeedback("Didn't fully follow instructions")}>
                Didn't fully follow instructions
              </button>
              <button type="button" className={"m-1 question-suggestion btn " + (this.state.feedback == "Other" ? 'btn-primary' : 'btn-secondary')} onClick={(e) => this.selectFeedback("Other")}>
                Other
              </button>
              <div className="input-group mb-3 searchbox rounded-6 mt-2 mb-3">
                <input type="" id="addtional_notes" value={this.state.addtional_notes} className="form-control bg-transparent border-0" placeholder="(Optional) Feel free add specific details" onChange={(e) => this.onChangeValueHandler(e)} autoComplete="off" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button variant="outlined" onClick={this.props.closePopup} className="btn btn-dark text-uppercase mr-3" data-dismiss="modal">{this.state.closeButtonText}</button>
            <button type="button" disabled={!this.state.feedback} onClick={() => this.sendFeedback()} className="btn btn-primary px-4 ml-0"> {this.state.confirmButtonText}</button>
          </Modal.Footer>
        </Modal>
      </div>


    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    feedback: state.dashboard.feedback
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      sendFeedback
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(DisLikeResponsePopUp);
