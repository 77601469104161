export const fetchMonth = (month) => {
  const shortMonthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];
  return shortMonthNames[month - 1] || '';
};

export const fetchDay = (day) => parseInt(day) || '';

export const fetchDayNumber = (date) => {
  const day = fetchDay(date.split("-")[2]);
  return `${day}`;
};

export const GFG_sortFunction = (a, b) => {
  const dateA = new Date(a.date).getTime();
  const dateB = new Date(b.date).getTime();
  return dateA > dateB ? 1 : -1;
};

// Convert label number to ordinal (1st, 2nd, 3rd, etc.)
export const labelOrdinal = (labelNumber) => {
  // Convert label number to ordinal (1st, 2nd, 3rd, etc.)
  let labelOrdinal = '';
  switch (labelNumber % 10) {
      case 1:
          labelOrdinal = labelNumber + 'st';
          break;
      case 2:
          labelOrdinal = labelNumber + 'nd';
          break;
      case 3:
          labelOrdinal = labelNumber + 'rd';
          break;
      default:
          labelOrdinal = labelNumber + 'th';
          break;
  }
  return labelOrdinal;
}

export const fontStyleForChart = {
  family: '"Roboto","Helvetica","Arial",sans-serif',
  color: '#212529',
  size: 12,
  titleSize: 16,
  weight: '400'
}