import React from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Pie } from 'react-chartjs-2';
import { fontStyleForChart } from "../../ResourceChart/helper";

class PieChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          dataSets: [],
          labelArray: [],
          datakey: ["timeSpend"]
        };

        this.type = this.props.type;
    }

    componentDidMount() {
        this.processData(this.props?.projectWiseData || []);
    }
    
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.projectWiseData, this.props.projectWiseData)) {
          this.processData(this.props?.projectWiseData || []);
        }
    }

    processData() {
        // Sort the array by the "projectName" property in ascending order
        const dataArr = _.sortBy(this.props?.projectWiseData, item => item.projectName.toLowerCase());
        const labelArr = dataArr.map(ele => ele.projectName);
        const utilizationData = dataArr.map(ele => ele.timeSpend);
        let utilizationTitle = '';
        if(this.type === 'project-employee') {
            utilizationTitle = 'Project Utilization';
        } else if(this.type === 'project-team') {
            utilizationTitle = 'Project Utilization';
        } else {
            utilizationTitle = 'Employee Utilization';
        }

        const dataValues = [{
          label: `${utilizationTitle}`,
          data: utilizationData,
        //   backgroundColor: "#0075FD"
        }];

        this.setState({
            data: dataArr,
            dataSets: dataValues,
            labelArray: labelArr
        });

        this.options = {
            plugins: {
                title: {
                    display: false,
                    text: `${utilizationTitle}`,
                    align: 'start',
                    font: {
                        size: fontStyleForChart.size,
                        weight: fontStyleForChart.weight,
                        family: fontStyleForChart.family
                    },
                    color: fontStyleForChart.color,
                    padding: {
                        bottom : 20,
                    }
                },
                legend: {
                    display: true,
                    labels: {
                        filter: (legendItem, data) => data.datasets[0].data[legendItem.index] !== 0,
                        font: {
                          size: fontStyleForChart.size,
                          weight: fontStyleForChart.weight,
                          family: fontStyleForChart.family
                        },
                        color: fontStyleForChart.color
                    }
                },
                datalabels: {
                    display: true,
                },
                tooltip: {
                    filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0,
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`;
                        }
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false
        };
    }

    render(){
        const { dataSets, labelArray } = this.state;
        const dataVal = {
            labels: labelArray,
            datasets: dataSets
        };

        return (
            <div id="pie-chart" className="ratio ratio-1x1">
                {this.state.dataSets !== null && this.state.dataSets.length > 0 && (
                    <Pie options={this.options} data={dataVal} />
                )}
            </div>
        );
    }

};

PieChartComponent.propTypes = {
    projectWiseData: PropTypes.array.isRequired, // Validate history prop
    type: PropTypes.string.isRequired, // Validate history prop
};

export default PieChartComponent;