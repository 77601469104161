import { FETCH_MONTHLY_REPORT, FETCH_TEAM_REPORT, FETCH_USER_MONTHLY_REPORT, FETCH_USER_REPORT } from "../actions/index";

const INITIAL_STATE = {
  teamReport: [],
  monthlyReport: [],
  userReport: [],
  monthlyUserReport: []
};
export default function utilizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case FETCH_TEAM_REPORT:
      return {
        ...state,
        teamReport: action.payload,
      }
    case FETCH_MONTHLY_REPORT:
      return {
        ...state,
        monthlyReport: action.payload,
      }
    case FETCH_USER_MONTHLY_REPORT:
      return {
        ...state,
        monthlyUserReport: action.payload,
      }
    case FETCH_USER_REPORT:
      return {
        ...state,
        userReport: action.payload,
      }
    default:
      return state;
  }
}
