import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import dashboardReducer from "./reducer-dashboard";
import securityReducer from "./securityReducer";
import userReducer from "./reducer-user";
import utilizationReducer from "./reducer-utilization";
import resourceUtilizationReducer from "./reducer-resource-utilization";
import employeeUtilizationReducer from "./reducer-employee-utilization";
import projectUtilizationReducer from "./reducer-project-utilization";

const allReducers = combineReducers({
  errors: errorReducer,
  security: securityReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  utilization: utilizationReducer,
  resourceUtilization: resourceUtilizationReducer,
  employeeUtilization: employeeUtilizationReducer,
  projectUtilization: projectUtilizationReducer
});

export default allReducers;
