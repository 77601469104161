import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as routes from "../../../constants/routes";
import { getEmployeesAndTeams, getEmployeesUtilization, getChartsOfEmployeesUtilization } from "../../../actions/index";
import UtilizationTabular from "./UtilizationTabular";
import BarChartComponent from "./BarChartComponent";
import PieChartComponent from "./PieChartComponent";
import LineChartComponent from "./LineChartComponent";
import moment from 'moment-timezone';

class EmployeeUtilization extends Component {
  constructor(props) {
    super(props); 
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

    const today = moment();
    const lastWeekEnd = today.clone().subtract(7, 'days'); // Last day of the previous week
    this.state = {
      selectedDate: {
        startDate: lastWeekEnd.toDate(),
        endDate: today.toDate()
      },
      selectedTeam: 'all',
      selectedEmployees: [],
      teams: [], // state to hold list of teams
      employees: [], // state to hold list of employees
      searchFilterData: [],
      projectWiseData: []
    }
  }

  async componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    } else {
      await this.fetchTeams();
    }
  }

  componentDidUpdate(prevProps) {
    // listing employee and teams data
    if (this.props.teamsAndEmployees && prevProps.teamsAndEmployees !== this.props.teamsAndEmployees) {
      this.setState({
        teams: this.props.teamsAndEmployees?.teams || [],
        employees: this.props.teamsAndEmployees?.employees || []
      });
    }

    // display data on tabular form
    if (this.props.searchFilterData && prevProps.searchFilterData !== this.props.searchFilterData) {
      this.setState({
        searchFilterData: this.props.searchFilterData
      });
    }
    
    // display data on chart form
    if (this.props.projectWiseData && prevProps.projectWiseData !== this.props.projectWiseData) {
      this.setState({
        projectWiseData: this.props.projectWiseData
      });
    }
  }

  handleDate(changeDateVal, dateKey) {
    this.setState(prevState => ({
      selectedDate: {
        ...prevState.selectedDate,
        [dateKey]: changeDateVal
      }
    }));
  }

  handleTeamChange = (event) => {
    this.setState({ selectedEmployees: [] });
    this.setState({ selectedTeam: event.target.value });
  }
  
  handleEmployeeChange = (selectedOptions) => {
    selectedOptions = (selectedOptions && !_.isArray(selectedOptions)) ? [selectedOptions] : selectedOptions;
    this.setState({ selectedEmployees: selectedOptions || [] });
  }

  fetchTeams = async() => {
    try {
      await this.props.getEmployeesAndTeams();
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  }

  handleSubmit = async(event) => {
    event.preventDefault();
    this.setState(prevState => ({
      searchFilterData: [], // Reset the tabular data
      projectWiseData: []  // Reset the chart data
    }));

    const employeeIds = !_.isEmpty(this.state.selectedEmployees) ? _.map(this.state.selectedEmployees, item => item.value) : [];
    await this.props.getEmployeesUtilization(this.state.selectedDate, this.state.selectedTeam, employeeIds);
    await this.props.getChartsOfEmployeesUtilization(this.state.selectedDate, this.state.selectedTeam, employeeIds);
  }

  render() {
    const { selectedDate, selectedTeam, teams, employees, selectedEmployees, searchFilterData, projectWiseData } = this.state;
    const hasSearchFilterData = !_.isEmpty(searchFilterData);
    const hasProjectWiseData = !_.isEmpty(projectWiseData) && projectWiseData.chartData && projectWiseData.chartData.length > 0;
    const chartType = projectWiseData?.type || '';

    const employeeOptions = employees.map(employee => ({
        value: employee.id,
        label: employee.emp_name
    }));

    const today = moment().toDate();

    return (
      <div className="flex-grow-1 pb-5 px-3">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Employee Utilization</h2>
        </div>
        <Container fluid className="mt-4 w-100 mx-auto d-flex flex-wrap flex-xl-nowrap justify-content-center">
          {selectedDate.startDate && selectedDate.endDate && 
            <Form className="mb-3 d-flex flex-wrap flex-xl-nowrap gap-3 border rounded-8 p-2 align-items-center justify-content-center mb-xl-4" onSubmit={this.handleSubmit}>
                <div className="d-flex align-items-center">
                    <div className="date-picker-label text-nowrap me-1">Start Date:</div>
                    <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'startDate')} 
                    value={selectedDate.startDate} 
                    clearIcon={null} 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    maxDate={selectedDate.endDate < today ? selectedDate.endDate : today} // set max date based on end date and today
                    onKeyDown={(e) => e.preventDefault()}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <div className="date-picker-label text-nowrap me-1">End Date:</div>
                    <DatePicker 
                    className={"datepicker flex-grow-1"}
                    onChange={(val) => this.handleDate(val, 'endDate')} 
                    value={selectedDate.endDate} 
                    clearIcon={null} 
                    dayPlaceholder="DD" 
                    monthPlaceholder="MM" 
                    yearPlaceholder="YYYY" 
                    minDate={selectedDate.startDate} // set min date based on start date
                    maxDate={today} // set max date to today
                    onKeyDown={(e) => e.preventDefault()}
                    />
                </div>
                <div className="d-flex align-items-center team-selection">
                    <select className="form-select" value={selectedTeam} onChange={this.handleTeamChange}>
                        <option key='all' value='all'>Team Selection</option>
                        {teams.map((team) => (
                            <option key={team.team_id} value={team.team_id}>{team.team_name}</option>
                        ))}
                        <option key='individual' value='individual'>Individual</option>
                    </select>
                </div>
                {selectedTeam === 'individual' &&
                    <div className="d-flex align-items-center">
                        <Select
                            className="flex-grow-1 theme-form-select"
                            value={selectedEmployees}
                            onChange={this.handleEmployeeChange}
                            options={employeeOptions}
                            placeholder="Employee Selection"
                            isClearable
                            // isMulti
                        />
                    </div>
                }
                <div className="d-flex align-items-center">
                    <Button type="submit" className="w-auto">Search</Button>
                </div>
            </Form>
          }
        </Container>
        <div className="container">
            <Row>
            {hasSearchFilterData &&
              <Col md={6} sm={12} xs={12}>
                <div className="resulttable position-relative">
                  <UtilizationTabular searchFilterData={searchFilterData} type="employee"/>
                </div>
              </Col>
            }
            {hasProjectWiseData && 
              <Col md={6} sm={12} xs={12}>
                  {chartType === 'bar' && <BarChartComponent projectWiseData={projectWiseData.chartData} type="Employee" />}
                  {chartType === 'pie' && <PieChartComponent projectWiseData={projectWiseData.chartData} type="Employee" />}
                  {chartType === 'line' && <LineChartComponent projectWiseData={projectWiseData.chartData} type="Employee" />}
              </Col>
            }
            {!_.isEmpty(projectWiseData) && projectWiseData.chartData && projectWiseData.chartData.length <= 0 && 
              <Col md={12} sm={12} xs={12}>
                  <div className='p-3 rounded-6 border mt-1 bg-light text-center'>
                    <strong>No Record Found.</strong>
                  </div>
              </Col>
            }
            </Row>
        </div>
      </div>
    );
  }
}

EmployeeUtilization.propTypes = {
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  getEmployeesAndTeams: PropTypes.func.isRequired,
  getEmployeesUtilization: PropTypes.func.isRequired,
  getChartsOfEmployeesUtilization: PropTypes.func.isRequired,
  teamsAndEmployees: PropTypes.object,
  searchFilterData: PropTypes.array,
  projectWiseData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentUser: state.security.user,
    leftMenuOpen: state.dashboard.leftMenuOpen,
    teamsAndEmployees: state.employeeUtilization.teamsAndEmployees,
    searchFilterData: state.employeeUtilization.searchFilterData,
    projectWiseData: state.employeeUtilization.projectWiseData
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ getEmployeesAndTeams, getEmployeesUtilization, getChartsOfEmployeesUtilization }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeUtilization);