import axios from "axios";
import { alertService } from "../components/GlobalComponent/alertService";
import {  API_URL, handleError, FETCH_FUNNEL_RESULTS, SET_FUNNEL_QUESTION, ALLOWED_WHITELISTED_DOMAINS, SAMPLE_QUESTIONS, FEEDBACK_LIKE, FEEDBACK_DISLIKE,FETCH_ASK_RESULTS } from "../actions/index";
export const setFunnelQuestion =
  (question) =>
    async (dispatch) => {
      dispatch({
        type: SET_FUNNEL_QUESTION,
        payload: question,
      });
    };

export const askFunnelQuestion =
  (question, token,) =>
    async (dispatch) => {
      try {
        let ask_funnel_url = `${API_URL}/funnel/askdatafunnel`;
        const parsedUrl = new URL(ask_funnel_url);

        if (ALLOWED_WHITELISTED_DOMAINS.includes(`${parsedUrl.protocol}//${parsedUrl.host}`)) {
            let payload = {
              "question_text": question
            }
            dispatch({
              type: FEEDBACK_DISLIKE,
              payload: 'both',
            });
            let res;
            res = await axios.post(ask_funnel_url, payload, {
              headers: {
                token: token
              }
            });

            let results = res.data;

            if (results.data === '' && results.message !== '') {
              alertService.error(results.message, { autoClose: true });
              dispatch({
                type: FETCH_ASK_RESULTS,
                payload: '',
              });
            }
            else {
              dispatch({
                type: FETCH_FUNNEL_RESULTS,
                payload: results.data,
              });
            }

           
        } else {
          alertService.error('Domain not allowed', { autoClose: true });
          const error = new Error('Domain not allowed');
          handleError(error, dispatch);
        }

      } catch (error) {
        dispatch({
          type: FETCH_ASK_RESULTS,
          payload: error.response.data.data,
        });
        handleError(error, dispatch);
      }
    };
export const getSampleQuestion = (type, token) => async (dispatch) => {
  try {
    let ask_question_url = API_URL + '/dashboard/getsamplequestions/' + type;

    let res;
    res = await axios.get(ask_question_url, {
      headers: {
        token: token
      }
    });

    let results = res.data;

    dispatch({
      type: SAMPLE_QUESTIONS,
      payload: results.data,
    });

  } catch (error) {
    handleError(error, dispatch);
  }
};

export const likeAnswer = (conversation_id) => async (dispatch) => {
  try {
    let ask_question_url = API_URL + '/dashboard/likeanswer';

    let res;

    dispatch({
      type: FEEDBACK_LIKE,
      payload: '',
    });
    res = await axios.post(ask_question_url, { conversation_id: conversation_id });
    let results = res.data;
    dispatch({
      type: FEEDBACK_LIKE,
      payload: 'like',
    });
    alertService.success(results.message, { autoClose: true });

  } catch (error) {
    handleError(error, dispatch);
  }
};

export const sendFeedback = (payload) => async (dispatch) => {
  try {
    let ask_question_url = API_URL + '/dashboard/dislikeanswer';

    let res;

    dispatch({
      type: FEEDBACK_DISLIKE,
      payload: '',
    });
    res = await axios.post(ask_question_url, { ...payload });
    let results = res.data;
    dispatch({
      type: FEEDBACK_DISLIKE,
      payload: 'dislike',
    });
    alertService.success(results.message, { autoClose: true });

  } catch (error) {
    handleError(error, dispatch);
  }
};