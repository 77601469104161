import { FETCH_TEAMS_EMPLOYEES, FETCH_EMPLOYEES_UTILIZATION, FETCH_EMPLOYEES_UTILIZATION_CHART } from "../actions/index";

const INITIAL_STATE = {
  teamsAndEmployees: {},
  searchFilterData: [],
  projectWiseData: {}
};

export default function employeeUtilizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TEAMS_EMPLOYEES:
      return {
        ...state,
        teamsAndEmployees: action.payload,
      }
    case FETCH_EMPLOYEES_UTILIZATION:
      return {
        ...state,
        searchFilterData: action.payload,
      }
    case FETCH_EMPLOYEES_UTILIZATION_CHART:
      return {
        ...state,
        projectWiseData: action.payload,
      }
    default:
      return state;
  }
}
