
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import moment from 'moment-timezone';
import { askUserQuestion, setQuestion, getSampleQuestion } from "../../../actions/index";
import * as routes from "../../../constants/routes";
import _ from 'lodash';
import Progress from "react-progress-2";
import Result from '../Result/Result';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
class CustomerBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workStartDate: new Date(),
      WorkStartDateHelperText: "",
      results: {},
      question: "",
      askedQuestion: "",
      displaySampleQuestion: true,
      sampleQuestionButton: false,
      feedback: ""
    };
    if (!sessionStorage.getItem("jwtToken") || sessionStorage.getItem("jwtToken") === '') {
      this.props.history.push(routes.LOGIN);
    }

  }

  componentDidMount() {

    let currentUser = this.props.currentUser;
    if (currentUser === '' || Object.keys(currentUser).length === 0) {
      window.location.href = '/';
    }
    let token = this.state.token;
    this.props.getSampleQuestion('Customers-Users', token);

  }

  componentDidUpdate(prevProps) {

    if (this.props.askResults && prevProps.askResults !== this.props.askResults) {
      this.setState({
        results: this.props.askResults,
        question: ''
      });
    }
    if (this.props.questionObj && prevProps.questionObj !== this.props.questionObj) {
      this.setState({
        askedQuestion: this.props.questionObj,
        displaySampleQuestion: false,
        sampleQuestionButton: true,
      });
    }
    if (this.props.sampleQuestions && prevProps.sampleQuestions !== this.props.sampleQuestions) {
      this.setState({ sampleQuestions: this.props.sampleQuestions });
    }
    if (this.props.feedback && prevProps.feedback !== this.props.feedback) {
      if (this.props.feedback === 'like') {
        let token = this.state.token;
        this.props.getSampleQuestion('Customers-Users', token);
      }
      this.setState({ feedback: this.props.feedback });
    }
  }
  onChangeWorkDate(val) {
    let startDate = moment(val).format('YYYY-MM-DD');
    this.setState({ workStartDate: startDate, WorkStartDateHelperText: "", question: '', results: {} });
  }
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.sendQuestion();
    }
  }

  onChangeValueHandler = (e) => {
    let value = e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };
  sendQuestion = () => {
    
    let question = this.state.question;
    if (question && question !== '') {
      question = question.trim();
    }
    let token = this.state.token;

    if (!Progress.isVisible() && question !== '') {
      this.setState({
        results: {
          records: [],
          columns: {}
        }
      });
      this.props.setQuestion(question);
      this.props.askUserQuestion(question, token);
      this.setState({ question: '' });
    }

  }

  selectQuestion(question) {
    this.setState({ question: question });
    setTimeout(() => {
      this.sendQuestion();
    }, 200);
  }

  showSampleQuestion() {
    this.setState({
      displaySampleQuestion: !this.state.displaySampleQuestion
    });
  }
  render() {

    return (
      <div className="flex-grow-1 pb-1 px-3 d-flex flex-column">
        <div className="text-center mt-3">
          <img src="/images/logo-symbol.png" className="" alt="send" />
          <h1 className="font-24 font-blue fw-bold my-2">BlattnerTech India</h1>
          <h2 className="font-32 text-dark fw-bold my-2">Customer/User Retriever Engine</h2>
          
        </div>

        <div className="w-100 max-w-768 mx-auto mt-auto">
          {this.state.WorkStartDateHelperText}
          {Object.values(this.state.results).length > 0 &&
            <div className="resulttable position-relative inquire-result-table">
              <Result question={this.state.askedQuestion} results={this.state.results} feedback={this.state.feedback}></Result>
            </div>
          }
          <div className="d-flex flex-wrap justify-content-center">
            {(this.state.sampleQuestions && this.state.sampleQuestions !== '' && this.state.displaySampleQuestion) &&
              this.state.sampleQuestions.map((data, i) => {
                return (
                  <button type="button" className="m-1 rounded-8 bg-light-gray p-2 border-gray question-suggestion text-center" onClick={(e) => this.selectQuestion(data.question)}  key={data.uuid}>
                    {data.question}
                  </button>
                )
              })
            }
          </div>
          <div className="d-flex align-items-center mt-2 mb-3">
            <div className="input-group searchbox">
              <input type="" id="question" value={this.state.question} className="form-control bg-transparent border-0" placeholder="Make a customer/user request" onChange={(e) => this.onChangeValueHandler(e)} onKeyDown={(e) => this.handleKeyDown(e)} autoComplete="off" />
              <button className="input-group-text enter-text-button bg-transparent border-0" disabled={!this.state.question} onClick={() => this.sendQuestion()}>
                <img src="/images/send_black_dark.png" className="" alt="send" />
              </button>
            </div>
            {(this.state.sampleQuestionButton && !_.isEmpty(this.state.sampleQuestions)) &&
              <Dropdown as={ButtonGroup} drop="end">
                <Dropdown.Toggle id="dropdown-button-dark-example1" className="question-btn rounded-circle ms-1">
                  <img src="/images/question.svg" className="" alt="send" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="m-0 p-0">
                  <Dropdown.Item className="text-decoration-none overflow-hidden rounded" onClick={() => this.showSampleQuestion()}>Sample Question</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          </div>
        </div>
      </div>
    );
  }
}

CustomerBoard.propTypes = {
  askResults: PropTypes.object, // Validate history prop
  history: PropTypes.object.isRequired, // Validate history prop
  currentUser: PropTypes.object.isRequired, // Validate currentUser prop
  questionObj: PropTypes.string,
  setQuestion: PropTypes.func,
  askUserQuestion: PropTypes.func,
  getSampleQuestion: PropTypes.func,
};

function mapStateToProps(state) {
  return {

    currentUser: state.security.user,
    askResults: state.dashboard.askResults,
    questionObj: state.dashboard.questionObj,
    sampleQuestions: state.dashboard.sampleQuestions,
    feedback: state.dashboard.feedback,
  };
}

function mapDispatchToProps(dispatch) {

  return bindActionCreators(
    {
      setQuestion,
      askUserQuestion,
      getSampleQuestion
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerBoard);